import React from 'react';
import axios from 'axios';
import ReminderForm from './ReminderForm';
import Reminder from './Reminder';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reminders: [],
      newName: '',
      newTime: ''
    }
  }

  async componentDidMount() {
    this.setState({
      reminders: (await axios.get('/api/reminders')).data
    })
  }

  createReminder = async (reminder) => {
    if (this.state.reminders.find((r) => r.name === reminder.name) === undefined) {
      let {id} = (await axios.post('/api/reminders', reminder)).data
      this.setState({
        reminders: this.state.reminders.concat({
          id: id,
          ...reminder
        })
      })
    }
  }

  deleteReminder = (id) => {
    return async () => {
      await axios.delete(`/api/reminders/${id}`)
      this.setState({
        reminders: this.state.reminders.filter((r) => r.id != id)
      })
    }
  }

  render() {
    return (
      <div>
        <h2>Add reminder</h2>
        <ReminderForm createReminder={this.createReminder.bind(this)} />
        <h2>Reminders:</h2>
        <ul>
          {this.state.reminders.map((r) => <Reminder key={r.id} r={r} deleteReminder={this.deleteReminder(r.id).bind(this)}/>)}
        </ul>
      </div>
    )
  }
}

export default App
