import React from 'react';

class ReminderForm extends React.Component {
    constructor(props) {
      super(props)
      this.createReminder = props.createReminder
      this.state = {
        newName: '',
        newTime: ''
      }
    }

    onReminderChange = (event) => {
      this.setState({
        newName: event.target.value
      })
    }
  
    onTimeChange = (event) => {
      this.setState({
        newTime: event.target.value
      })
    }
  
  
    addReminder = (event) => {
        event.preventDefault()
        this.setState({
            newName: '',
            newTime: ''
        })
        try {
            let time = new Date(this.state.newTime)
            this.createReminder({
            name: this.state.newName,
            timestamp: time.toISOString()
        })
        } catch {}
    }

    render() {
        return (
            <form onSubmit={this.addReminder}>
            <div>
                Name: <input
                value={this.state.newName}
                onChange={this.onReminderChange}
                />
                Time: <input
                value={this.state.newTime}
                onChange={this.onTimeChange}
                />
            </div>
            <div>
                <button type="submit">Add</button>
            </div>
            </form>
        )
    }
}

export default ReminderForm;